.pending_table {
  background: #fff;
  box-shadow: 3px 3px 6px 3px #00000008;
  overflow: hidden;
}

.pending_table thead tr th {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.pending_table tbody tr {
  /* border-bottom: 1px solid #eee; */
  margin-bottom: 10px;
}

.pending_table tbody tr td {
  vertical-align: middle;
  text-align: center;
}
