.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 35px;
  color: black;
  cursor: pointer;
  z-index: 10;
}

.right-arrow {
  right: -50px;
}

.left-arrow {
  left: -50px;
}
