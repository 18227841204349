.dashboard_tab_button {
  margin-bottom: 20px;
}

.dashboard_tab_button li a {
  border: 1px solid #e8e8e8;
  border-bottom: none;
  color: #dddddd;
  font-weight: 500;
  font-size: 15px;
  display: block;
  padding: 18px 15px;
  text-transform: uppercase;
  background-color: #b46e3c;
}

.dashboard_tab_button li a:last-child {
  border-bottom: 1px solid #e8e8e8;
}

.dashboard_tab_button li a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

.dashboard_tab_button li a.active,
.dashboard_tab_button li a:hover {
  background-color: #e07808;
  border-color: #fff;
  color: #fff;
}
