.image-size {
  /* Default size for extra small and small screens */
  width: 100%;
}

@media (min-width: 576px) {
  /* Medium screens */
  .image-size {
    width: 100%; /* Adjust as needed */
  }
}

@media (min-width: 768px) {
  /* Large screens */
  .image-size {
    width: 100%; /* Adjust as needed */
  }
}

@media (min-width: 992px) {
  /* Extra large screens */
  .image-size {
    width: 100%; /* Adjust as needed */
  }
}
